const MenuIcon = () => (
  <svg viewBox="0 0 24 24" width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M4 7L7 7M20 7L11 7"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>{" "}
      <path
        d="M20 17H17M4 17L13 17"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>{" "}
      <path
        d="M4 12H7L20 12"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>{" "}
    </g>
  </svg>
);
export default MenuIcon;
